import Heading from "atoms/Heading";
import Button from "atoms/Button";
import Text from "atoms/Text";
import Image from "next/image";
import Container from "atoms/Container";
import TextLoop from "atoms/TextLoop";
import { useRouter } from "next/router";

const HeroSection = () => {
    const texts = [' Create Checklist', ' Conduct Inspections', ' Generate Reports'];
    const router = useRouter();
    return (
        <div className="py-14 md:pt-16 flex items-center relative lg:min-h-[90vh] min-[1600px]:min-h-[75vh] min-[2000px]:min-h-[60vh]">
            <Image src="/images/hero-bg.png" layout="fill" />
            <Container>
                <div className="flex items-center justify-between relative z-10 gap-6">
                    <div className="lg:max-w-[501px] text-center lg:text-left">
                        <Heading color="text-primary-900" fontWeight="font-bold" type="h4" className="pb-4" fontSize="text-xl md:text-[1.75rem]"> Resilience 360 </Heading>
                        <Heading color="text-black" fontWeight="font-bold" type="h1" fontSize="text-3xl md:text-[42px]" className="md:leading-[3.5rem] pb-4">
                            Your all-in-one solution to
                            <TextLoop texts={texts} className="inline text-primary-900" />
                        </Heading>
                        <Text fontWeight="font-medium" variant="bodyLarge">
                            Transform your approach to risk management with our powerful AI-Driven digital suite of features designed
                            to fortify your safety and drive success.
                        </Text>
                        <Button
                            label="Start Your Resilience Journey Now!"
                            size="medium"
                            shadow="medium"
                            className="my-6 mx-auto lg:mx-0"
                            onClick={() => router.push('/contact-us')}
                        />
                    </div>
                </div>
            </Container>
            <div className="absolute right-0 hidden lg:flex items-center justify-center w-[55%] h-[90%] transform top-[56%] -translate-y-1/2">
                <div className="relative w-full h-full">
                    <Image src="/images/hero-banner.svg" objectFit="contain" objectPosition='center' alt="Resilience" layout="fill"></Image>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
