import Image from "next/image";
import PropTypes from "prop-types";
import Heading from "../atoms/Heading";
import Icon from "atoms/Icon";
import Text from "atoms/Text";
import Tooltip from "./ToolTip";


const CardImage = ({ cardimageUrl, cardIconUrl, headingText, onClick, headingType,
  headingTypeSecond, headingTextSecond, cardImageHeight, cardBodyText, iconWidth, iconHeight, toolTipLocation }) => {
  return (
    <Tooltip
      message={cardBodyText}
      position={toolTipLocation}
    >
      <div
        className="relative w-full py-3 md:py-5 px-6 md:px-8 flex-1 cursor-pointer CardImage"
        onClick={onClick}
      >
        <Image src={cardimageUrl} alt="features" height={cardImageHeight} layout="fill" className="object-cover rounded-2xl w-full" />
        <div className="relative z-1">
          <div className="flex justify-between mb-2">
            <Icon iconSrc={cardIconUrl} iconAlt="" iconWidth={iconWidth} iconHeight={iconHeight} />
            <Icon iconSrc="/images/arrow-right.svg" iconAlt="arrow" iconWidth="28" iconHeight="28" />
          </div>
          <Heading fontWeight="font-bold" type={headingType} color="text-white" className="whitespace-nowrap">
            {headingText}
          </Heading>
          <Heading fontWeight="font-semibold" type={headingTypeSecond} color="text-white" className="mt-2">
            {headingTextSecond}
          </Heading>
          <Text fontWeight="font-medium" className="text-white mt-2 cardBodyText">
            {cardBodyText}
          </Text>
        </div>
      </div>
    </Tooltip>
  );
};

CardImage.propTypes = {
  cardimageUrl: PropTypes.string.isRequired,
  cardIconUrl: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  headingType: PropTypes.string.isRequired,
  headingTextSecond: PropTypes.string.isRequired,
  headingTypeSecond: PropTypes.string.isRequired,
  cardImageHeight: PropTypes.string.isRequired,
  cardBodyText: PropTypes.string.isRequired,
  iconWidth: PropTypes.string.isRequired,
  iconHeight: PropTypes.string.isRequired,
  cardIconClass: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};


CardImage.defaultProps = {
  headingType: 'h5',
  headingTypeSecond: 'h6',
  cardImageHeight: '140',
  iconWidth: '54',
  iconHeight: '54'
}


export default CardImage;