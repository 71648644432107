import Image from "next/image";
import PropTypes from "prop-types";
import Heading from "../atoms/Heading";
import Button from "atoms/Button";

const FlipCard = ({ image, heading, onClick, width, height, lists }) => {
  return (
    <div className={`flip-card cursor-pointer rounded-2xl overflow-hidden ${width} ${height}`} onClick={onClick}>
      <div className="flip-card-inner w-full h-full transform relative">
      
        <div className="absolute top-0 left-0 w-full h-full flip-card-front">
          <Image
            src={image}
            alt="Services"
            layout="fill"
            className="object-cover rounded-2xl brightness-50"
          />
          <div className="w-full h-full relative z-1">
            <div className="flip-card-fron-inner flex justify-between gap-4 px-4 py-4 lg:px-6 lg:py-10 items-end h-full">
              <Heading type="h4" color="text-white" className="font-bold">
                {heading}
              </Heading>
              <div className="flex-none">
                <Image src="/images/arrow-right.svg" alt="arrow" width={24} height={24} />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-primary-900 text-white p-5 flex flex-col justify-end items-start flip-card-back">
          <div className="md:block hidden">
          <Image
            src='/images/service-icon.png'
            width={65}
            height={60}
          />
          </div>
          <Heading type="h4" className="font-bold text-white my-3">{heading}</Heading>
          <ul className="text-sm list-disc pl-4 font-medium">
            {
              lists.map((li, i) => {
                return <li key={i}>{li.title}</li>
              })
            }
          </ul>
          <Button
            label="Read more"
            size="small"
            style="borderLess"
            className="bg-white mt-7 hidden md:block ml-auto"
          />
        </div>
      </div>
    </div>

  );
};

FlipCard.defaultProps = {
  image: '',
  heading: '',
  onClick: () => { },
  width: 'w-full',
  height: 'h-180px md:h-[300px]',
  lists: []
};

FlipCard.propTypes = {
  image: PropTypes.string,
  heading: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  lists: PropTypes.arrayOf()
};


export default FlipCard;