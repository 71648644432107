import CardImageBig from "molecules/CardImageBig";
import Heading from "atoms/Heading";
import Container from "atoms/Container";
import Carousel from "molecules/Carousel";
import { SwiperSlide } from "swiper/react";
import SerivcesData from "@/components/Helper/services-data";
import FlipCard from "molecules/FlipCard";
import { useRouter } from "next/router";

const OurServices = () => {
    const router = useRouter();
    return (
        <Container className="py-6 md:py-14">
            <Heading fontWeight="font-semibold" className="text-center mb-6 md:mb-9"> Our Services</Heading>
            <Carousel
                loop
                slidesPerView={1.25}
                className="block lg:hidden"
            >
                {
                    SerivcesData.map((service, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <FlipCard
                                    key={i}
                                    image={service.image}
                                    heading={service.title}
                                    height="h-[130px]"
                                    onClick={() => router.push(`/services/${service.id}`)}
                                />
                            </SwiperSlide>
                        )
                    })
                }
            </Carousel>

            <div className="justify-center flex-wrap gap-20 hidden lg:flex">
                {
                    SerivcesData.map((service, i) => {
                        return (
                            <FlipCard
                                key={i}
                                image={service.image}
                                heading={service.title}
                                height="h-[417px]"
                                width="w-full max-w-[340px]"
                                lists={service.subCategories}
                                onClick={() => router.push(`/services/${service.id}`)}
                            />
                        )
                    })
                }
            </div>
        </Container>
    );
};

export default OurServices;
