import Heading from "atoms/Heading";
import Text from "atoms/Text";
import Image from "next/image";
import Button from "atoms/Button";
import Container from "atoms/Container";
import uspData from "@/components/Helper/usp-content";
import Carousel from "molecules/Carousel";
import { SwiperSlide } from "swiper/react";
import { useRouter } from "next/router";

const OurUSPMobile = () => {
    const router = useRouter();
    return (
        <div className="bg-primary-900 block lg:hidden" id="usp">
            <Container className="py-7">
                <Heading fontWeight="font-extrabold" className="text-center" color="text-white">Our USP</Heading>
                
                <div className="">
                    <Carousel
                        loop
                        slidesPerView={1}
                        arrows
                        pagination={false}
                    >
                        {
                            uspData.map((usp, i) => (
                                <SwiperSlide key={i}>
                                    <div className="w-full max-w-[90%] mx-auto flex items-center justify-center my-6">
                                        <Image src={usp.imgurl} width={280} height={350} objectFit="contain"></Image>
                                    </div>
                                    <div className="flex flex-col gap-3 text-center">
                                        <Heading color="text-white" fontWeight="font-extrabold" type="h2" >{usp.heading}</Heading>
                                        <Text className="text-white" fontWeight="font-semibold">{usp.subHeading}</Text>
                                        <Button
                                            label="Schedule a Demo Now!"
                                            shadow="medium"
                                            style="borderLess"
                                            className="bg-white w-max mx-auto mt-1"
                                            onClick={() => router.push('/contact-us')}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Carousel>
                </div>
            </Container>
        </div>

    );
};

export default OurUSPMobile;
