import Image from 'next/image'
import React from 'react'

function ToolTip({ children, message, position = 'left' }) {
  const style = {
    left: 'top-1/2 transform -translate-y-1/2 -left-[100%]',
    right: 'top-1/2 transform -translate-y-1/2 -right-[100%]'
  }
  const iconPosition = {
    left: "top-1/2 transform -translate-y-1/2 rotate-90 -right-6",
    right: "top-1/2 transform -translate-y-1/2 -rotate-90 -left-6"
  }
  return (
    <div className='relative group'>
      {children}
      <div className={`hidden z-50 group-hover:block transition-all absolute bg-white shadow text-neutral-800 text-sm p-4 rounded-md w-full ${style[position]}`}>
        {message}
        <div className={`absolute ${iconPosition[position]}`}>
          <Image
            src='/images/angle-pointer.png'
            width={35}
            height={18}
          />
        </div>
      </div>
    </div>
  )
}

export default ToolTip