import React from "react";
import HeroSection from 'organisms/HeroSection';
import OurServices from 'organisms/OurServices';
import OurUSP from 'organisms/OurUSP';
import RequestDemo from 'organisms/RequestDemo';
import BeforeAfterResilience from 'organisms/BeforeAterResilience';
import Features from 'organisms/Features';
import OurUSPMobile from "organisms/OurUSPMobile";
import Head from 'next/head';

const Homepage = () => {
  return (
    <div>
      <Head>
        <title>GetRight: Pan India Risk &amp; Compliance Management for SME</title>
        <meta
          name="description"
          content="AI-Driven digital suite of features designed to fortify safety and compliance needs."
        />
      </Head>
      <HeroSection />
      <Features />
      <OurUSP />
      <OurUSPMobile />
      <OurServices />
      <BeforeAfterResilience />
      <RequestDemo />
    </div>
  );
};

export default Homepage;
