import Heading from "atoms/Heading";
import Image from "next/image";
import Button from "atoms/Button";
import Container from "atoms/Container";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

const RequestDemo = ({ title, buttonLabel, description, className }) => {
    const router = useRouter();
    return (
        <div className={`md:h-[402px] h-auto flex items-center relative py-12 ${className}`}>
            <Image src="/images/request-bg.png" layout="fill" />
            <Container className='md:pt-12'>
                <div className="container mx-auto md:px-6 relative z-10">
                    <div className="flex items-center justify-between">
                        <div className="flex-1">
                            <Heading type="h2" className="md:mb-4 mb-2 text-white font-extrabold">{title}</Heading>
                            <i className="md:w-9 md:h-1.5 w-[36px] h-[4px] bg-white block md:mb-4 mb-2"></i>
                            <Heading color="text-white" fontWeight="font-medium" type="h6" className="md:mb-4 mb-2">{description}</Heading>
                            <Button
                                label={buttonLabel}
                                shadow="medium"
                                style="borderLess"
                                className="bg-white md:mt-4 mt-2 hidden md:block"
                                onClick={() => router.push('/contact-us')}
                            />
                            <Button
                                label={buttonLabel}
                                shadow="medium"
                                style="borderLess"
                                className="bg-white md:mt-4 mt-2 !px-3 !py-2 !text-sm block md:hidden"
                                onClick={() => router.push('/contact-us')}
                            />
                        </div>
                        <div className="flex-none flex justify-end w-[78px] md:w-[220px]">
                            <Image src="/images/requestDevice.png" width="217" height="237" alt="RequestDevice"></Image>
                        </div>
                    </div>
                </div>
            </Container>
        </div>

    );
};

RequestDemo.defaultProps = {
    title: 'Request GetRight Demo', 
    buttonLabel: 'Schedule a Demo Now!', 
    description: 'Ready to see GetRight in action?',
    className: ''
};

RequestDemo.propTypes = {
    title: PropTypes.string, 
    buttonLabel: PropTypes.string, 
    description: PropTypes.string,
    className: PropTypes.string
};


export default RequestDemo;
