const uspData = [
    {
        id: 1,
        imgurl:'/images/automatedSolution.png',
        heading: 'An automated solution for instantly capturing data',
        subHeading: `Bid farewell to the traditional Hardcopy paperwork and digital spreadsheets. Enables team to conduct mobile
        inspections/surveys seamlessly on their handheld devices enabling to capture offline data as well. Detailed
        data can be captured with precision and makes the work multiple times faster.`,
        list: [
            'Intuitive interface, user-friendly with no need for training or complex setup.',
            'Personalized digital checklists: covering photos, annotations, and e-signatures.',
            'Operates on phones, tablets, and computers, supporting Android & iOS; functional without an internet connection.'
        ]
    },
    {
        id: 2,
        imgurl:'/images/Reportsautomatically.png',
        heading: 'Reports automatically produced with valuable insights',
        subHeading: `Don't waste time on manual reports. After each check, easily make reports that help you decide and improve things.`,
        list: [
            'Retrieve intelligent ratings, pinpoint repeated shortcomings, and scrutinize past patterns.',
            'Set up automatic sharing of reports and give tasks to people.',
            'Make customisable reports which is based on your routine as well as task specific business requirements.'
        ]
    },
    {
        id: 3,
        imgurl:'/images/Overseequality.png',
        heading: 'Oversee quality, safety, and standards across your business operations',
        subHeading: `Ensure health and safety compliance through risk assessments and internal audits, adhering to ISO quality
        standards and conducting self-inspections. Implement quality control measures by following process audit
        checklists and maintaining adherence to internal and external standards, SOPs, and compliance checks.`,
        list: [
            'Conduct risk assessments for health and safety, ensuring compliance checks.',
            'Adherence to ISO quality and safety standards, with self-inspections and internal audits.',
            'Quality control measures include adherence to standards and process audit checklists.',
            'Internal audits, process audit checklists, and health & safety risk assessments.',
            'Adherence to internal as well as external standards, SOPs, and compliance checks for quality control.'
        ]
    },
    {
        id: 4,
        imgurl:'/images/FacilitateEasy.png',
        heading: 'Facilitate easy workplace communication across hierarchy and teams',
        subHeading: `Enable your teams to interact and work together more effectively towards common goals. Introduce tailored
        workflows and establish uniform procedures across all locations, ensuring clear guidance on tasks, methods,
        and timing.`,
        list: [
            'Assign follow-up tasks efficiently to the right people for effective management.',
            'Dispatch reports, reminders, and escalations through streamlined workflows.',
            'Implement scheduled audits and introduce updated processes.',
            'Schedule audits and roll out updated processes for enhanced efficiency.'
        ]
    },
    {
        id: 5,
        imgurl:'/images/Importantdashboard.png',
        heading: 'Important Observations with synchronous dashboard',
        subHeading: `Utilize the gathered information to understand things better: keep an eye on how things are going, spot
        patterns, and fix ongoing problems.`,
        list: [
            'Keep an eye on all aspects using a dynamic dashboard.',
            'Recognize patterns, compare performance between sites/teams, and delve into specific details.',
            'Enhance your business by making informed decisions.',
            'Utilize the dashboard to monitor various aspects comprehensively.',
            'Make improvements based on well-analyzed data and insights.'
        ]
    },
];

export default uspData;