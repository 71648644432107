import Container from "atoms/Container";
import Heading from "atoms/Heading";
import CardImage from "molecules/CardImage";
import Carousel from "molecules/Carousel";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { SwiperSlide } from "swiper/react";

const features = [
  {
    id: 1,
    icon: '/images/360.svg',
    image: '/images/features-06.png',
    title: '360-Degree Safety Audits',
    cardBodyText: 'Conduct thorough 360-degree risk assesments/audits that leave no stone unturned. Our platform enables you to delve deep into every facet of your operations, identifying potential risks and vulnerabilities. From physical hazards to operational inefficiencies, our audits cover it all, empowering you with insights for comprehensive risk management.',
    toolTip: 'right'
  },
  {
    id: 2,
    icon: '/images/real-time.svg',
    image: '/images/features-05.png',
    title: 'Real-time Monitoring',
    cardBodyText: 'In the dynamic business environment, timing is everything. With GetRight’s real-time monitoring, you receive instant alerts and insights. Stay ahead of potential issues, enabling proactive decision-making. Our real-time monitoring ensures you are well-informed, allowing for swift responses to emerging risks and incidents.',
    toolTip: 'left'
  },
  {
    id: 3,
    icon: '/images/spiral.svg',
    image: '/images/features-04.png',
    title: 'Customizable Assessments',
    cardBodyText: 'Every industry has its unique challenges. GetRight allows you to tailor assessments to your industry-specific requirements. Whether it\'s compliance standards, industry regulations, or internal protocols, our platform adapts to your needs. Drive a tailored approach to risk management with customizable assessments.',
    toolTip: 'right'
  },
  {
    id: 4,
    icon: '/images/collaborative.svg',
    image: '/images/features-03.png',
    title: 'Collaborative Platform',
    cardBodyText: 'Resilience is a team effort. Our collaborative platform fosters communication and teamwork for optimal efficiency. Centralize your risk management efforts, streamline communication among team members, and enhance overall efficiency. With GetRight, collaboration is not just a feature; it\'s a cornerstone of your resilience strategy.',
    toolTip: 'left'
  },
  {
    id: 5,
    icon: '/images/planning.svg',
    image: '/images/features-02.png',
    title: 'Incident Response Planning',
    cardBodyText: 'Prepare for the unexpected with GetRight\'s incident response planning. Develop, implement, and refine robust response plans to navigate challenges seamlessly. Our platform guides you through the entire incident response lifecycle, ensuring your organization is well-prepared to handle any disruptions.',
    toolTip: 'right'
  },
  {
    id: 6,
    icon: '/images/compliance.svg',
    image: '/images/features-01.png',
    title: 'Regulatory Compliance',
    cardBodyText: 'Staying compliant with evolving standards is critical. GetRight simplifies regulatory compliance. Seamlessly integrate industry-specific laws and regulations into your risk management processes. Our platform keeps you informed and ensures that your organization effortlessly adheres to the latest standards.',
    toolTip: 'left'
  }
];


const Features = () => {

  const router = useRouter();

  return (
    <div className="relative">
      <Image src="/images/featurebg.png" layout="fill" />
      <Container className="md:py-20 py-6 relative z-10">
        <div className="flex flex-wrap gap-6 md:gap-10 w-full items-center">
          <div className="flex-1 relative text-center lg:text-left">
            <div className="hidden md:block absolute -top-24 -left-20">
              <Image
                src="/images/feature-globe.png"
                width={214}
                height={217}
              />
            </div>
            <Heading color="text-primary-900" fontWeight="font-bold" type="h4" className="mb-2 md:mb-4" fontSize="text-xl md:text-[1.75rem]">Features</Heading>
            <Heading fontWeight="font-extrabold" type="h2" className="mb-2 md:mb-4">Protecting Your Business, Every Step of the Way</Heading>
            <Heading fontWeight="font-normal" type="h4" className="md:mb-4" color="text-neutral-500">Resilience 360</Heading>
          </div>
          <div className="w-full max-w-[700px] mx-auto flex-none">
            <div className="">
              <Carousel
                loop
                slidesPerView={1.25}
                className="block md:hidden"
              >
                {
                  features.map((feature, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <CardImage
                          cardIconUrl={feature.icon}
                          cardimageUrl={feature.image}
                          headingText={feature.title}
                          onClick={() => router.push('/features')}
                        />
                      </SwiperSlide>
                    )
                  })
                }

              </Carousel>
              <div className="flex-col hidden md:grid grid-cols-2 gap-2">
                {
                  features.map((feature, i) => {
                    return (
                      <CardImage
                        cardIconUrl={feature.icon}
                        cardimageUrl={feature.image}
                        headingText={feature.title}
                        toolTipLocation={feature.toolTip}
                        className='h-full'
                        cardBodyText={feature.cardBodyText}
                        onClick={() => router.push('/features')}
                      />
                    )
                  })
                }
              </div>

            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Features;
