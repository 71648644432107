import Container from "atoms/Container";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import { Check, X } from "lucide-react";
import Image from "next/image";

const BeforeAfterResilience = () => {

    const afterResilience = [
        'Mobile Auditing',
        'Real-Time Reports',
        'Auto Assignment & Follow up',
        'Dynamic Dashboard',
        'Customisable Ready Templates'
    ];

    const beforeResilience = [
        'Paper Work',
        'Delayed Analysis',
        'Manual Assignment and Tracking',
        'Manual Reports',
        'Individual Wisdom'
    ];

    return (
        <div className="relative my-6 lg:my-12 lg:py-10">
            <div className="absolute bg-primary-900 md:rounded-l-xl rounded-0 block w-full xxs:w-[51%] lg:w-[50%] h-1/2 bottom-0 xxs:bottom-auto xxs:h-full -z-50 right-0 lg:-top-2"></div>
            <Container className='flex md:items-center justify-between overflow-hidden flex-col xxs:flex-row'>
                <div className="flex-1 max-w-[460px]">
                    <Heading fontWeight="font-normal" type="h3" className="md:text-left text-center mt-2 lg:mt-0">Before </Heading>
                    <Heading fontWeight="font-bold" type="h3" className="mb-4 md:text-left text-center">Resilience 360 </Heading>
                    <div className="flex flex-col md:gap-3">
                        {
                            beforeResilience.map((item, i) => {
                                return (
                                    <div className="flex items-center gap-1 md:gap-3 border-b md:border-0 min-h-[50px] lg:min-h-max py-1 md:py-0 xxs:pr-2">
                                        <X className="flex-none" color="#F34B5F" />
                                        <Text key={i} className="font-medium" variant="bodyLarge" fontSize="text-sm md:text-lg" align="text-left">{item}</Text>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="flex-1 relative w-full ">
                    <div className="flex gap-16 items-center">
                        <div className="lg:block hidden flex-none">
                            <Image src="/images/beforeafterAnimate.gif" width={294} height={600} ></Image>
                        </div>
                        <div className="text-white w-full">
                            <Heading fontWeight="font-normal" type="h3" className="text-white md:text-left text-center mt-2 lg:mt-0">After </Heading>
                            <Heading fontWeight="font-bold" type="h3" className="mb-4 text-white md:text-left text-center">Resilience 360 </Heading>
                            <div className="flex flex-col md:gap-3">
                                {
                                    afterResilience.map((item, i) => {
                                        return (
                                            <div className="flex items-center gap-1 md:gap-3 border-b md:border-0 min-h-[50px] lg:min-h-max py-1 md:py-0">
                                                <Check className="flex-none" size={20} color="#4BF384" />
                                                <Text key={i} className="font-medium" variant="bodyLarge" fontSize="text-sm md:text-lg" align="text-left">{item}</Text>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default BeforeAfterResilience;
