import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

const TextLoop = ({ texts, interval, className}) => {

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  return <p className={`w-full ${className}`}>{texts[currentTextIndex]}</p>;
};

TextLoop.defaultProps = {
    texts: ['Text 1', 'Text 2', 'Text 3'], 
    interval: 2000 , 
    className: ''
};

TextLoop.propTypes = {
    texts: PropTypes.arrayOf(), 
    interval: PropTypes.number , 
    className: PropTypes.string
};

export default TextLoop;
