import Heading from "atoms/Heading";
import Text from "atoms/Text";
import Image from "next/image";
import Button from "atoms/Button";
import Container from "atoms/Container";
import uspData from "@/components/Helper/usp-content";
import { CheckCircle2 } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

const OurUSP = () => {
    const router = useRouter();
    const [visibleSectionId, setVisibleSectionId] = useState(null);
    const sectionRefs = useRef({});

    useEffect(() => {

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            for (const [sectionId, ref] of Object.entries(sectionRefs.current)) {
                if (ref.current) {
                    const sectionTop = ref.current.offsetTop;
                    const sectionBottom = sectionTop + ref.current.clientHeight;

                    if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                        setVisibleSectionId(sectionId);
                        break;
                    }
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="bg-primary-900 min-h-screen hidden lg:block" id="usp">
            <Container className="py-14">
                <Heading fontWeight="font-extrabold" className="text-center" color="text-white">Our USP</Heading>
                <div className="grid grid-cols-2 gap-x-10">
                    {
                        uspData.map((usp, index) => {
                            const sectionId = `section-${index}`;
                            sectionRefs.current[sectionId] = useRef(null);
                            return (
                                <>
                                    <div 
                                        className={`w-full sticky top-0 h-screen flex items-center justify-center transition-all duration-100 ${visibleSectionId === sectionId ? 'opacity-1' : 'opacity-0'}`}
                                    >
                                        <Image src={usp.imgurl} width={280} height={600} objectFit="contain" />
                                    </div>
                                    <div ref={sectionRefs.current[sectionId]} id={sectionId} className="min-h-screen py-6 flex flex-col justify-center">
                                        <Heading color="text-white" fontWeight="font-extrabold" type="h2" className="mb-5">{usp.heading}</Heading>
                                        <Text className="text-white mb-4" fontWeight="font-semibold" variant="bodyLarge" fontSize="text-sm 2xl:text-lg">{usp.subHeading}</Text>
                                        <div className="flex flex-col gap-3">
                                            {
                                                usp?.list.map((li, i) => {
                                                    return (
                                                        <div key={i} className="flex items-center gap-2 text-sm 2xl:text-lg text-white">
                                                            <CheckCircle2 className="flex-none" />
                                                            {li}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="text-center mt-6 md:block flex justify-center">
                                            <Button
                                                label="Schedule a Demo Now!"
                                                shadow="medium"
                                                style="borderLess"
                                                className="bg-white"
                                                onClick={() => router.push('/contact-us')}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>

            </Container>
        </div>

    );
};

export default OurUSP;
